const EventEmitter = require('events');

export const jsonObjectToFormData = obj => {
  const body = new FormData();
  for (const key of Object.keys(obj)) {
    if (Array.isArray(obj[key])) {
      if (obj[key].length) {
        for (const entry of obj[key]) {
          body.append(key, entry);
        }
      } else {
        body.append(key, []);
      }
    } else {
      body.append(key, obj[key]);
    }
  }
  return body;
};

export const jsonObjectToQueryString = obj => {
  const params = new URLSearchParams();

  if (obj && Object.keys(obj).length) {
    for (const key of Object.keys(obj)) {
      if (Array.isArray(obj[key])) {
        if (obj[key].length) {
          for (const entry of obj[key]) {
            params.append(key, entry);
          }
        } else {
          params.append(key, []);
        }
      } else if (obj[key] !== null && typeof obj[key] !== 'undefined') {
        params.append(key, obj[key]);
      }
    }
  }

  return params.toString();
};
export class HttpClient extends EventEmitter {
  constructor(getAuthToken) {
    super();
    if (!getAuthToken) {
      throw new Error('getAuthToken function should be defined');
    }
    this.getAuthToken = getAuthToken;

    for (const method of ['get', 'post', 'put', 'patch', 'delete']) {
      this[method] = (url, body, headers, image = false) =>
        this.request(url, {
          method: method.toUpperCase(),
          body,
          headers,
          image
        });
    }
  }

  async request(url, args = {}) {
    if (!url || !url.length) {
      throw new Error('Url is not defined');
    }

    const { json = true, image = false, ...options } = args;

    const authToken = this.getAuthToken();

    options.headers = {
      Authorization: authToken && `Bearer ${authToken}`,
      Cache: 'no-cache',
      ...(options.headers || {})
    };
    if (json && !image) {
      options.headers['Content-Type'] = 'application/json';
      if (options.body) {
        options.body = JSON.stringify(options.body);
      }
    }

    const response = await fetch(url, options);

    if (response.status < 200 || response.status > 300) {
      const payload = {
        url,
        response
      };
      this.emit(response.status, payload);
      return Promise.reject(payload);
    }

    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else if (image) {
      return response.blob();
    }

    return response.text();
  }
}

export default new HttpClient(() => localStorage.getItem('HUB_AUTH_TOKEN'));
