import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_MINIONS_TASKS = am(`GET_MINIONS_TASKS`);

export default {
  namespaced: true,
  ...factory({
    state: {
      tasks: { data: [], pages: 0, total: 0 },
      isGetMinionsTasksRequestPending: false,
      isGetMinionsTasksRequestFailed: false
    },
    mutations: {
      [GET_MINIONS_TASKS.STARTED](state) {
        state.isGetMinionsTasksRequestPending = true;
        state.isGetMinionsTasksRequestFailed = false;
      },
      [GET_MINIONS_TASKS.FAILED](state) {
        state.isGetMinionsTasksRequestPending = false;
        state.isGetMinionsTasksRequestFailed = true;
      },
      [GET_MINIONS_TASKS.COMPLETED](state, { tasks, pages, total }) {
        state.tasks = { data: tasks, pages, total };

        state.isGetMinionsTasksRequestPending = false;
      }
    },
    actions: {
      async getMinionsTasks({ commit }, { startDate, populate, fields, endDate, assignee, completionStatus, pagination, queryString }) {
        try {
          commit(GET_MINIONS_TASKS.STARTED);

          let tasks = [];
          let numberOfPagesToFetch = 1;
          let finalPage = pagination?.page || 1;
          let finalPageSize = pagination?.pageSize || 1000;
          let paginationData = 0;

          const queryParams = new URLSearchParams();

          if (startDate) {
            queryParams.append('filters[taskUpdatedAt][$gte]', startDate);
          }
          if (endDate) {
            queryParams.append('filters[taskUpdatedAt][$lte]', endDate);
          }
          if (completionStatus) {
            queryParams.append('filters[completionStatus]', completionStatus);
          }

          if (populate) {
            for (const pop of populate) {
              queryParams.append('populate', pop);
            }
          }

          if (fields) {
            for (const field of fields) {
              queryParams.append('fields', field);
            }
          }

          if (assignee) {
            queryParams.append('filters[$or][0][assignees][$contains]', `,${assignee},`);
            queryParams.append('filters[$or][1][assignees][$startsWith]', `${assignee},`);
            queryParams.append('filters[$or][2][assignees][$endsWith]', `,${assignee}`);
            queryParams.append('filters[$or][3][assignees]', assignee);
          }

          do {
            queryParams.set('pagination[page]', finalPage);
            queryParams.set('pagination[pageSize]', finalPageSize);

            const query = queryParams.toString() + (queryString ? `&${queryString}` : '');
            const response = await httpClient.get(`/api/streams/minion-dojo-api/api/tasks?${query}`);
            const { data, meta } = response;

            tasks = tasks.concat(data);
            paginationData = meta.pagination;
            numberOfPagesToFetch = pagination?.page ? 1 : paginationData.pageCount;

            finalPage++;
            // If pagination exists, fetch only once
            //if no pagination exists  - parse all pages available after filter
          } while (!pagination && finalPage <= numberOfPagesToFetch);

          commit(GET_MINIONS_TASKS.COMPLETED, { tasks, pages: paginationData.pagesCount, total: paginationData.total });
        } catch (error) {
          commit(GET_MINIONS_TASKS.FAILED);
          console.error('Error fetching minion tasks:', error);
          throw error;
        }
      }
    }
  })('minionTasks')
};
