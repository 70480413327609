import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_COMMENTS = am('GET_COMMENTS');
const ADD_COMMENT = am('ADD_COMMENT');
const ADD_COMMENT_ATTACHMENT = am('ADD_COMMENT_ATTACHMENT');

export default {
  namespaced: true,
  ...factory({
    state: {
      collection: {},
      isGetCommentsRequestPending: false,
      isGetCommentsRequestFailed: false,
      isAddCommentRequestPending: false,
      isAddCommentRequestFailed: false
    },
    mutations: {
      [GET_COMMENTS.STARTED](state) {
        state.isGetCommentsRequestFailed = false;
        state.isGetCommentsRequestPending = true;
        state.collection = [];
      },
      [GET_COMMENTS.COMPLETED](state, collection) {
        state.collection = collection;

        state.isGetCommentsRequestPending = false;
      },
      [GET_COMMENTS.FAILED](state) {
        state.isGetCommentsRequestFailed = true;
        state.isGetCommentsRequestPending = false;
      },
      [ADD_COMMENT.STARTED](state) {
        state.isAddCommentRequestPending = true;
        state.isAddCommentRequestFailed = false;
      },
      [ADD_COMMENT.COMPLETED](state, item) {
        state.collection.data.push(item);

        state.isAddCommentRequestPending = false;
      },
      [ADD_COMMENT.FAILED](state) {
        state.isAddCommentRequestPending = false;
        state.isAddCommentRequestFailed = true;
      },
      [ADD_COMMENT_ATTACHMENT.STARTED](state) {
        state.isAddCommentRequestPending = true;
        state.isAddCommentRequestFailed = false;
      },
      [ADD_COMMENT_ATTACHMENT.COMPLETED](state, item) {
        state.collection.data.push(item);

        state.isAddCommentRequestPending = false;
      },
      [ADD_COMMENT_ATTACHMENT.FAILED](state) {
        state.isAddCommentRequestPending = false;
        state.isAddCommentRequestFailed = true;
      }
    },
    actions: {
      async getComments({ commit }, minionId) {
        try {
          commit(GET_COMMENTS.STARTED);
          const collection = await httpClient.get(
            `/api/streams/minion-dojo-api/api/comments?populate=*&filters[minionId][$eq]=${minionId}&pagination[pageSize]=250`
          );

          commit(GET_COMMENTS.COMPLETED, collection);
        } catch (e) {
          commit(GET_COMMENTS.FAILED);
          throw e;
        }
      },
      async add({ commit }, item) {
        try {
          commit(ADD_COMMENT.STARTED);

          const response = await httpClient.post(`/api/streams/minion-dojo-api/api/comments`, { data: { ...item } });

          const posted = response?.data;
          if (!posted?.id) {
            throw new Error(`Failed to add new comment with contents: ${item.content}`);
          }

          const hasAttachments = Array.isArray(item.attachments) && item.attachments.length > 0;

          if (hasAttachments) {
            const newComment = await httpClient.get(`/api/streams/minion-dojo-api/api/comments/${posted.documentId}?populate=*`);
            commit(ADD_COMMENT.COMPLETED, newComment.data);
          } else {
            commit(ADD_COMMENT.COMPLETED, posted);
          }
        } catch (e) {
          commit(ADD_COMMENT.FAILED);
          throw e;
        }
      },
      async attAttachment({ commit }, { id }) {
        try {
          commit(ADD_COMMENT_ATTACHMENT.STARTED);

          // const response = await httpClient.delete(`/api/minion-dojo-api/api/minions${id}`);

          // if (response.status !== 204) {
          //   throw new Error(`Failed to delete item with id: ${id}`);
          // }

          commit(ADD_COMMENT_ATTACHMENT.COMPLETED, { id });
        } catch (e) {
          commit(ADD_COMMENT_ATTACHMENT.FAILED);
          throw e;
        }
      }
    }
  })('comments')
};
